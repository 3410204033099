body {
    display: grid;
    background: #054D29;
    background-image: url("../assets/images/bg.jpg");
    background-repeat: no-repeat;
    background-position: center center;
    background-attachment: fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.main-container {
    vertical-align: middle;
    position: absolute;
    min-width: 100%;
    min-height: 100%;
}

.login-container {
    position: fixed;
    min-width: 100%;
    top: 50%;
    left: 50%;
    overflow: visible;
}

.logo-img {
    max-width: 100%;
    max-height: 100%;
    vertical-align: middle;
    position: relative;
    object-fit: contain;
}

.login-box {
    vertical-align: middle;
    position: relative;
    object-fit: contain;
}

.back-opacity {
    position: fixed;
    z-index: -1;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    background: #ffffff; /* Цвет фона */
    margin: 0 auto; /* Выравниваем слой по центру */
    opacity: 0.6; /* Значение прозрачности */
    border-radius: 15px;
}
@media all and (min-width: 320px) {
    .login-container{
        -webkit-transform: translate(-50%, -80%);
        transform: translate(-50%, -80%);
    }
    .logo-img {
        top: 50%;
        -webkit-transform: translate(0, 0);
        transform: translate(0, 0);
    }
    .login-box {
        width: 80%;
        top: 40%;
        left: 10%;
        -webkit-transform: translate(0, 0);
        transform: translate(0, 0);
    }
    .back-opacity {
        width: 90%; /* Ширина слоя */
        height: 95%;
    }
}

@media all and (min-width: 600px) {
    .login-container{
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    }
    .logo-img {
        top: 50%;
        -webkit-transform: translate(0, -20%);
        transform: translate(0, -50%);
    }
    .login-box {
        width: 100%;
        top: 50%;
        left: 0%;
        -webkit-transform: translate(0, -20%);
        transform: translate(0, -50%);
    }
    .back-opacity {
        width: 70%; /* Ширина слоя */
        height: 60%;
    }
}
